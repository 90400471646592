// import dayjs from 'dayjs'
import md5 from 'js-md5'
export var SearchList = [
  { labe: '合同编号', code: 'number', type: 'input' },
  {
    labe: '业务类型',
    code: 'businessType',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '生产物料类', value: '1' },
      { label: '资产类', value: '2' },
      { label: '服务类', value: '3' },
      { label: '基建类', value: '4' },
      { label: '委托采购', value: '5' },
      { label: '其它协议', value: '6' }
    ]
  },
  { labe: '我方主体', code: 'subject', type: 'input' },
  {
    labe: '客户主体',
    code: 'mySubject',
    type: 'select',
    option: [
    ]
  }, {
    labe: '合同状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待我签', value: '2' },
      { label: '待他签', value: '3' },
      { label: '已签章', value: '4' },
      { label: '作废', value: '6' },
      { label: '待确认', value: '7' },
      { label: '待归档', value: '8' },
      { label: '草稿', value: '9' }

    ]
  }, {
    labe: '生效日期',
    code: 'createDate',
    type: 'daterange'
  }
]
export var SearchData = {
  number: '',
  businessType: '',
  subject: '',
  mySubject: '',
  status: '',
  // createDate: '',
  strStartDate: '',
  strEndDate: '',
  differentiatedContract: 1,
  type: 1
}

export var tableField = [
  { label: '合同编号', code: 'number', type: 'input', width: '60%' },
  {
    label: '业务类型',
    code: 'businessType',
    type: 'function',
    width: '50%',
    handle: (index, data) => {
      return data.businessType === '1' ? '生产物料类' : data.businessType === '2' ? '资产类'
        : data.businessType === '3' ? '服务类' : data.businessType === '4' ? '基建类'
          : data.businessType === '5' ? '委托采购' : '其它协议'
    }
  },
  // { label: '业务类型', code: 'businessType', type: 'input', width: '' },
  { label: '合同名称', code: 'name', type: 'input', width: '90%' },
  { label: '我方主体', code: 'subject', type: 'input', width: '90%' },
  // { label: '客户主体', code: 'mySubjectCode', type: 'input', width: '90%' },
  {
    label: '客户主体',
    code: 'mySubjectCode',
    type: 'vnode',
    width: '140',
    handle: function (scope) {
      if (scope) {
        return {
          data () {
            console.log('', scope)
            if (scope.row.mySubjectCode == null || undefined) {
              scope.row.mySubjectCode = ''
            }
            console.log('scope', scope.row.mySubjectCode)
            return {
              // mySubjectCode: scope.row.mySubjectCode.split('-')[1]
              mySubjectCode: scope.row.mySubjectCode.split('-')[1]
            }
          },
          template: '<span>{{mySubjectCode}}</span>'
        }
      }
    }
  },
  // {
  //   label: '客户主体',
  //   code: 'mySubject',
  //   type: 'function',
  //   width: '45%',
  //   handle: function (index, row) {
  //     if (row.mySubject === 'KK') {
  //       return '可控'
  //     }
  //     if (row.mySubject === 'SG') {
  //       return '曙光'
  //     }
  //     if (row.mySubject === 'NC') {
  //       return '宁畅'
  //     }
  //     if (row.mySubject === 'TL') {
  //       return '腾龙'
  //     }
  //     if (row.mySubject === 'SC') {
  //       return '数创'
  //     }
  //   }
  // },
  {
    label: '合同介质',
    code: 'medium',
    type: 'function',
    width: '45%',
    handle: (index, data) => {
      return data.medium === 0 ? '纸质合同' : '电子合同'
    }
  },
  { label: '采购员', code: 'ypersonincharge', type: 'input', width: '45%' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '40%',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === 0 ? '待审核' : data.status === 1 ? '驳回'
        : data.status === 2 ? '待我签' : data.status === 3 ? '待他签'
          : data.status === 4 ? '已签章' : data.status === 5 ? '草稿'
            : data.status === 6 ? '作废' : data.status === 7 ? '待确认' : data.status === 8 ? '待归档' : '草稿'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '40%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      },
      {
        actionLabel: '编辑',
        operationButton: 'edit',
        stateField: 'status',
        stateSymbol: '=',
        stateValue: '5'
      }
    ]
  }
  // {
  //   label: '操作',
  //   type: 'vnode',
  //   handle: function (scope) {
  //     if (scope) {
  //       let buttonHtml = ''
  //       if (scope.row.differentiatedContract === 0 && scope.row.status === 7) {
  //         buttonHtml = '<el-button  size="mini" type="text" @click="clk(`edit`)">编辑</el-button>'
  //       }
  //       return {
  //         methods: {
  //           clk: (data) => {
  //             // 点击查看按钮状态为草稿的到起草页面,携带数据
  //             if (data === 'see') {
  //               this.$router.push({ params: { viewName: 'ContractSign' }, query: { id: scope.row.id } })
  //             } else {
  //               this.$router.push({ params: { viewName: 'SupplierDraft' }, query: { id: scope.row.id } })
  //             }
  //           }
  //         },
  //         template: `<span>
  //           <el-button size="mini" type="text" @click="clk('see')" >查看</el-button>
  //           ${buttonHtml}
  //         </span>`
  //       }
  //     }
  //   }
  // }
]

export var tableData = []
export var menudata = [{
  label: '+起草合同',
  action: 'draftContract',
  id: 1
}
]
